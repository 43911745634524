import React from 'react'
import { graphql } from 'gatsby'
import HighlightsSeries from '../../components/common/highlightsSeries'
import ProductHeader from '../../components/header/productHeader'
import CommonFooter from '../../components/footer/commonFooter'
import CallToAction from '../../components/common/callToAction'
import TextImageRight from '../../components/common/textWithRightImage'
import Seo from '../../components/seo/seo'
import SvgFirmwareDevelopment from '../../illustrations/Firmware/FirmwareDevelopment'
import SvgEmbeddedElectronics from '../../illustrations/Firmware/EmbeddedElectronics'
import { useTranslation } from 'react-i18next'

const FirmwareDevelopment = ({ data }) => {
  const { t } = useTranslation();

  return (
  <>
    <Seo
      title={t('firmwareDev.seoTitle')}
      description={t('firmwareDev.seoDescription')}
      seoImage={data.seoImage.childImageSharp.resize.src}
    />
    <ProductHeader
      isGradient=""
      headerTitle={t('firmwareDev.headerTitle')}
      headerSubtitle={t('firmwareDev.headerSubtitle')}
      animatedIllustration={<SvgFirmwareDevelopment />}
    />
    <HighlightsSeries
      title={t('firmwareDev.title')}
      mainOne={t('firmwareDev.mainOne')}
      mainTwo={t('firmwareDev.mainTwo')}
      mainThree={t('firmwareDev.mainThree')}
    />
    <div className="section"></div>
    <CallToAction
      title={t('firmwareDev.callToActionTitle')}
      link="/contatti"
      buttonText={t('firmwareDev.callToActionButton')}
    />
    <div className="section"></div>
    <TextImageRight
      titleOne={t('firmwareDev.titleOne')}
      textOne={t('firmwareDev.textOne')}
      // fixed={data.placeholder.childImageSharp.fixed}
      animatedIllustration={<SvgEmbeddedElectronics />}
      style={{ width: '560px' }}
    />
    <CommonFooter />
  </>
)
}

export default FirmwareDevelopment

export const firmwareQuery = graphql`
  query {
    hero: file(relativePath: { eq: "heros/services/prova.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    seoImage: file(relativePath: { eq: "illustrations/technology_stack.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
  }
`
