import React from 'react'
import { motion } from 'framer-motion'

function SvgEmbeddedElectronics(props) {
  return (
    <svg
      viewBox="0 0 1024 1024"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <defs>
        <linearGradient
          y1={674.072}
          id="EmbeddedElectronics_svg__B"
          x1={660.371}
          y2={674.072}
          x2={694.018}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={850.778}
          id="EmbeddedElectronics_svg__a"
          x1={418.08}
          y2={850.778}
          x2={429.944}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={850.778}
          id="EmbeddedElectronics_svg__c"
          x1={462.787}
          y2={850.778}
          x2={474.651}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={850.778}
          id="EmbeddedElectronics_svg__d"
          x1={484.652}
          y2={850.778}
          x2={496.516}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={850.778}
          id="EmbeddedElectronics_svg__e"
          x1={506.516}
          y2={850.778}
          x2={518.38}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={850.778}
          id="EmbeddedElectronics_svg__f"
          x1={527.771}
          y2={850.778}
          x2={539.635}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={850.778}
          id="EmbeddedElectronics_svg__g"
          x1={549.025}
          y2={850.778}
          x2={560.889}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={850.778}
          id="EmbeddedElectronics_svg__h"
          x1={570.401}
          y2={850.778}
          x2={582.265}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={850.778}
          id="EmbeddedElectronics_svg__i"
          x1={591.533}
          y2={850.778}
          x2={603.397}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={850.778}
          id="EmbeddedElectronics_svg__j"
          x1={614.008}
          y2={850.778}
          x2={625.872}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={564.97}
          id="EmbeddedElectronics_svg__k"
          x1={411.727}
          y2={564.97}
          x2={431.812}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(39.638 276.526) scale(1.04434)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={564.97}
          id="EmbeddedElectronics_svg__l"
          x1={411.727}
          y2={564.97}
          x2={431.812}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(152.182 276.047) scale(1.04434)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={556.784}
          id="EmbeddedElectronics_svg__m"
          x1={421.446}
          y2={556.784}
          x2={433.31}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={556.784}
          id="EmbeddedElectronics_svg__n"
          x1={444.166}
          y2={556.784}
          x2={456.03}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={556.784}
          id="EmbeddedElectronics_svg__o"
          x1={466.153}
          y2={556.784}
          x2={478.017}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={556.784}
          id="EmbeddedElectronics_svg__p"
          x1={488.018}
          y2={556.784}
          x2={499.882}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={556.784}
          id="EmbeddedElectronics_svg__q"
          x1={509.883}
          y2={556.784}
          x2={521.747}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={556.784}
          id="EmbeddedElectronics_svg__r"
          x1={531.137}
          y2={556.784}
          x2={543.001}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={556.784}
          id="EmbeddedElectronics_svg__s"
          x1={552.391}
          y2={556.784}
          x2={564.255}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={556.784}
          id="EmbeddedElectronics_svg__t"
          x1={573.767}
          y2={556.784}
          x2={585.631}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={556.784}
          id="EmbeddedElectronics_svg__u"
          x1={594.899}
          y2={556.784}
          x2={606.763}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={556.784}
          id="EmbeddedElectronics_svg__v"
          x1={617.375}
          y2={556.784}
          x2={629.239}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={564.97}
          id="EmbeddedElectronics_svg__w"
          x1={411.727}
          y2={564.97}
          x2={431.812}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-26.452 -81.461) scale(1.04434)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={565.807}
          id="EmbeddedElectronics_svg__x"
          x1={542.465}
          y2={565.807}
          x2={562.549}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-26.452 -81.461) scale(1.04434)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={607.5}
          id="EmbeddedElectronics_svg__y"
          x1={660.553}
          y2={607.5}
          x2={694.2}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={630.22}
          id="EmbeddedElectronics_svg__z"
          x1={660.491}
          y2={630.22}
          x2={694.138}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={652.207}
          id="EmbeddedElectronics_svg__A"
          x1={660.431}
          y2={652.207}
          x2={694.078}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={850.778}
          id="EmbeddedElectronics_svg__b"
          x1={440.8}
          y2={850.778}
          x2={452.664}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={695.937}
          id="EmbeddedElectronics_svg__C"
          x1={660.311}
          y2={695.937}
          x2={693.958}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={717.191}
          id="EmbeddedElectronics_svg__D"
          x1={660.253}
          y2={717.191}
          x2={693.9}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={738.445}
          id="EmbeddedElectronics_svg__E"
          x1={660.195}
          y2={738.445}
          x2={693.842}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={759.821}
          id="EmbeddedElectronics_svg__F"
          x1={660.137}
          y2={759.821}
          x2={693.784}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={780.953}
          id="EmbeddedElectronics_svg__G"
          x1={660.079}
          y2={780.953}
          x2={693.726}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={803.428}
          id="EmbeddedElectronics_svg__H"
          x1={660.017}
          y2={803.428}
          x2={693.664}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={684.664}
          id="EmbeddedElectronics_svg__I"
          x1={684.729}
          y2={684.664}
          x2={704.813}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-26.452 -81.461) scale(1.04434)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={792.077}
          id="EmbeddedElectronics_svg__J"
          x1={685.509}
          y2={792.077}
          x2={705.594}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-26.452 -81.461) scale(1.04434)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={703.763}
          id="EmbeddedElectronics_svg__K"
          x1={420.683}
          y2={703.763}
          x2={624.79}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -52.642) scale(1.04434)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={605.256}
          id="EmbeddedElectronics_svg__L"
          x1={345.519}
          y2={605.256}
          x2={379.166}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={627.976}
          id="EmbeddedElectronics_svg__M"
          x1={345.457}
          y2={627.976}
          x2={379.104}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={649.963}
          id="EmbeddedElectronics_svg__N"
          x1={345.397}
          y2={649.963}
          x2={379.044}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={671.828}
          id="EmbeddedElectronics_svg__O"
          x1={345.337}
          y2={671.828}
          x2={378.984}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={693.692}
          id="EmbeddedElectronics_svg__P"
          x1={345.277}
          y2={693.692}
          x2={378.924}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={714.946}
          id="EmbeddedElectronics_svg__Q"
          x1={345.219}
          y2={714.946}
          x2={378.866}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={736.201}
          id="EmbeddedElectronics_svg__R"
          x1={345.161}
          y2={736.201}
          x2={378.808}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={757.577}
          id="EmbeddedElectronics_svg__S"
          x1={345.103}
          y2={757.577}
          x2={378.75}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={778.709}
          id="EmbeddedElectronics_svg__T"
          x1={345.045}
          y2={778.709}
          x2={378.692}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={801.184}
          id="EmbeddedElectronics_svg__U"
          x1={344.983}
          y2={801.184}
          x2={378.63}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-32.591 -47.697) scale(1.04434)"
        >
          <stop stopColor="#afaeae" offset={0} />
          <stop stopColor="#e3dedf" offset={0.974} />
        </linearGradient>
        <linearGradient
          y1={564.97}
          id="EmbeddedElectronics_svg__V"
          x1={411.727}
          y2={564.97}
          x2={431.812}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-120.245 153.798) scale(1.04434)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={564.97}
          id="EmbeddedElectronics_svg__W"
          x1={411.727}
          y2={564.97}
          x2={431.812}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-119.213 18.492) scale(1.04434)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={398.156}
          id="EmbeddedElectronics_svg__X"
          x1={251.424}
          y2={398.156}
          x2={813.591}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1.0565 0 0 1.56146 -45.754 -204.884)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={142.185}
          id="EmbeddedElectronics_svg__Y"
          x1={879.816}
          y2={142.185}
          x2={934.127}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1.0565 0 0 1.0122 -128.91 65.978)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={174.062}
          id="EmbeddedElectronics_svg__Z"
          x1={615.862}
          y2={173.972}
          x2={711.279}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1.47842 0 0 1.48676 -367.886 -70.15)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={161.505}
          id="EmbeddedElectronics_svg__aa"
          x1={387.379}
          y2={161.505}
          x2={444.817}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1.0565 0 0 1.0122 -43.699 60.958)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={161.505}
          id="EmbeddedElectronics_svg__ab"
          x1={387.379}
          y2={161.505}
          x2={444.817}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(1.0565 0 0 1.0122 -241.52 -3.293)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
      </defs>
      <g>
        <path
          d="M404.026 823.251h12.39v35.106h-12.39v-35.106z"
          fill="url(#EmbeddedElectronics_svg__a)"
        />
        <path
          d="M427.753 823.251h12.39v35.106h-12.39v-35.106z"
          fill="url(#EmbeddedElectronics_svg__b)"
        />
        <path
          d="M450.715 823.251h12.39v35.106h-12.39v-35.106z"
          fill="url(#EmbeddedElectronics_svg__c)"
        />
        <path
          d="M473.549 823.251h12.39v35.106h-12.39v-35.106z"
          fill="url(#EmbeddedElectronics_svg__d)"
        />
        <path
          d="M496.384 823.251h12.39v35.106h-12.39v-35.106z"
          fill="url(#EmbeddedElectronics_svg__e)"
        />
        <path
          d="M518.58 823.251h12.39v35.106h-12.39v-35.106z"
          fill="url(#EmbeddedElectronics_svg__f)"
        />
        <path
          d="M540.777 823.251h12.39v35.106h-12.39v-35.106z"
          fill="url(#EmbeddedElectronics_svg__g)"
        />
        <path
          d="M563.101 823.251h12.39v35.106h-12.39v-35.106z"
          fill="url(#EmbeddedElectronics_svg__h)"
        />
        <path
          d="M585.17 823.251h12.39v35.106h-12.39v-35.106z"
          fill="url(#EmbeddedElectronics_svg__i)"
        />
        <path
          d="M608.642 823.251h12.39v35.106h-12.39v-35.106z"
          fill="url(#EmbeddedElectronics_svg__j)"
        />
        <path
          d="M469.621 866.547c0-5.792 4.695-10.488 10.487-10.488s10.487 4.696 10.487 10.488-4.695 10.487-10.487 10.487-10.487-4.695-10.487-10.487z"
          fill="url(#EmbeddedElectronics_svg__k)"
        />
        <path
          d="M582.165 866.068c0-5.792 4.696-10.488 10.488-10.488s10.487 4.696 10.487 10.488-4.695 10.487-10.487 10.487-10.488-4.695-10.488-10.487z"
          fill="url(#EmbeddedElectronics_svg__l)"
        />
        <g>
          <path
            d="M407.541 516.222h12.39v35.105h-12.39v-35.105z"
            fill="url(#EmbeddedElectronics_svg__m)"
          />
          <path
            d="M431.269 516.222h12.39v35.105h-12.39v-35.105z"
            fill="url(#EmbeddedElectronics_svg__n)"
          />
          <path
            d="M454.23 516.222h12.391v35.105H454.23v-35.105z"
            fill="url(#EmbeddedElectronics_svg__o)"
          />
          <path
            d="M477.065 516.222h12.39v35.105h-12.39v-35.105z"
            fill="url(#EmbeddedElectronics_svg__p)"
          />
          <path
            d="M499.899 516.222h12.39v35.105h-12.39v-35.105z"
            fill="url(#EmbeddedElectronics_svg__q)"
          />
          <path
            d="M522.096 516.222h12.39v35.105h-12.39v-35.105z"
            fill="url(#EmbeddedElectronics_svg__r)"
          />
          <path
            d="M544.292 516.222h12.39v35.105h-12.39v-35.105z"
            fill="url(#EmbeddedElectronics_svg__s)"
          />
          <path
            d="M566.616 516.222h12.39v35.105h-12.39v-35.105z"
            fill="url(#EmbeddedElectronics_svg__t)"
          />
          <path
            d="M588.685 516.222h12.39v35.105h-12.39v-35.105z"
            fill="url(#EmbeddedElectronics_svg__u)"
          />
          <path
            d="M612.157 516.222h12.39v35.105h-12.39v-35.105z"
            fill="url(#EmbeddedElectronics_svg__v)"
          />
          <path
            d="M403.531 508.559c0-5.792 4.695-10.487 10.487-10.487s10.487 4.695 10.487 10.487-4.695 10.487-10.487 10.487-10.487-4.695-10.487-10.487z"
            fill="url(#EmbeddedElectronics_svg__w)"
          />
          <path
            d="M540.065 509.433c0-5.792 4.695-10.487 10.487-10.487 5.793 0 10.488 4.695 10.488 10.487s-4.695 10.488-10.488 10.488c-5.792 0-10.487-4.696-10.487-10.488z"
            fill="url(#EmbeddedElectronics_svg__x)"
          />
        </g>
        <g>
          <path
            d="M692.389 580.592l-.034 12.39-35.105-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__y)"
          />
          <path
            d="M692.324 604.32l-.034 12.39-35.105-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__z)"
          />
          <path
            d="M692.261 627.281l-.034 12.39-35.105-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__A)"
          />
          <path
            d="M692.199 650.116l-.034 12.39-35.105-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__B)"
          />
          <path
            d="M692.136 672.95l-.034 12.39-35.105-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__C)"
          />
          <path
            d="M692.076 695.146l-.034 12.39-35.105-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__D)"
          />
          <path
            d="M692.015 717.343l-.034 12.39-35.105-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__E)"
          />
          <path
            d="M691.954 739.667l-.034 12.39-35.105-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__F)"
          />
          <path
            d="M691.894 761.735l-.034 12.39-35.105-.095.034-12.39 35.105.095z"
            fill="url(#EmbeddedElectronics_svg__G)"
          />
          <path
            d="M691.829 785.208l-.033 12.39-35.106-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__H)"
          />
          <path
            d="M688.637 633.56c0-5.792 4.695-10.487 10.487-10.487s10.488 4.695 10.488 10.487-4.696 10.487-10.488 10.487-10.487-4.695-10.487-10.487z"
            fill="url(#EmbeddedElectronics_svg__I)"
          />
          <path
            d="M689.452 745.736c0-5.792 4.695-10.488 10.487-10.488s10.488 4.696 10.488 10.488-4.696 10.487-10.488 10.487-10.487-4.695-10.487-10.487z"
            fill="url(#EmbeddedElectronics_svg__J)"
          />
        </g>
        <path
          d="M382.218 546.733h259.95c10.419 0 18.865 8.447 18.865 18.866v240.284c0 10.419-8.446 18.865-18.865 18.865h-259.95c-10.419 0-18.865-8.446-18.865-18.865V565.599c0-10.419 8.446-18.866 18.865-18.866z"
          fill="#333"
        />
        <path
          d="M425.72 583.014h175.205c10.48 0 18.976 8.496 18.976 18.976v160.672c0 10.48-8.496 18.975-18.976 18.975H425.72c-10.48 0-18.975-8.495-18.975-18.975V601.99c0-10.48 8.495-18.976 18.975-18.976z"
          fill="url(#EmbeddedElectronics_svg__K)"
        />
        <path
          d="M435.933 720.962h75.431c3.778 0 6.841 2.127 6.841 4.751 0 2.624-3.063 4.751-6.841 4.751h-75.431c-3.779 0-6.842-2.127-6.842-4.751 0-2.624 3.063-4.751 6.842-4.751zM437.485 750.784h92.548c4.636 0 8.394 2.127 8.394 4.751 0 2.624-3.758 4.751-8.394 4.751h-92.548c-4.636 0-8.394-2.127-8.394-4.751 0-2.624 3.758-4.751 8.394-4.751zM436.382 736.2h80.39c4.027 0 7.291 2.127 7.291 4.751 0 2.624-3.264 4.751-7.291 4.751h-80.39c-4.026 0-7.291-2.127-7.291-4.751 0-2.624 3.265-4.751 7.291-4.751z"
          fill="#333"
        />
        <g>
          <path
            d="M363.387 578.249l-.034 12.39-35.105-.096.033-12.39 35.106.096z"
            fill="url(#EmbeddedElectronics_svg__L)"
          />
          <path
            d="M363.322 601.976l-.034 12.39-35.105-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__M)"
          />
          <path
            d="M363.259 624.938l-.034 12.39-35.105-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__N)"
          />
          <path
            d="M363.196 647.772l-.033 12.39-35.105-.096.033-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__O)"
          />
          <path
            d="M363.134 670.606l-.034 12.39-35.105-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__P)"
          />
          <path
            d="M363.073 692.802l-.033 12.391-35.106-.096.034-12.39 35.105.095z"
            fill="url(#EmbeddedElectronics_svg__Q)"
          />
          <path
            d="M363.013 714.999l-.034 12.39-35.105-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__R)"
          />
          <path
            d="M362.952 737.323l-.034 12.39-35.105-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__S)"
          />
          <path
            d="M362.891 759.392l-.034 12.39-35.105-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__T)"
          />
          <path
            d="M362.827 782.864l-.034 12.39-35.105-.096.034-12.39 35.105.096z"
            fill="url(#EmbeddedElectronics_svg__U)"
          />
          <path
            d="M309.738 743.818c0-5.792 4.695-10.488 10.488-10.488 5.792 0 10.487 4.696 10.487 10.488s-4.695 10.487-10.487 10.487c-5.793 0-10.488-4.695-10.488-10.487z"
            fill="url(#EmbeddedElectronics_svg__V)"
          />
          <path
            d="M310.77 608.512c0-5.792 4.695-10.487 10.487-10.487s10.488 4.695 10.488 10.487c0 5.793-4.696 10.488-10.488 10.488s-10.487-4.695-10.487-10.488z"
            fill="url(#EmbeddedElectronics_svg__W)"
          />
        </g>
        <path
          d="M566.105 622.12c0-10.479 8.495-18.974 18.974-18.974 10.48 0 18.975 8.495 18.975 18.974 0 10.479-8.495 18.974-18.975 18.974-10.479 0-18.974-8.495-18.974-18.974z"
          fillOpacity={0.553}
          fill="#fff"
        />
      </g>
      <path
        d="M402.547 516.472L219.874 305.857l565.236 1.414-158.764 210.615"
        opacity={0.404}
        fill="url(#EmbeddedElectronics_svg__X)"
        name="ConnectionShape"
      />
      <g>
        <path
          d="M719.028 188.734H865.97c6.741 0 12.205 5.236 12.205 11.694v154.336c0 6.458-5.464 11.693-12.205 11.693H719.028c-6.741 0-12.205-5.235-12.205-11.693V200.428c0-6.458 5.464-11.694 12.205-11.694z"
          fill="#333"
        />
        <motion.g
          animate={{
            scaleY: [1, 0, 1],
          }}
          transition={{ duration: 0.2, loop: Infinity, repeatDelay: 2 }}
        >
          <path
            d="M810.61 219.441h37.384c5.521 0 9.998-4.273 9.998-9.544 0-5.271-4.477-9.544-9.998-9.544H810.61c-5.522 0-9.998 4.273-9.998 9.544 0 5.271 4.476 9.544 9.998 9.544z"
            fill="url(#EmbeddedElectronics_svg__Y)"
          />
        </motion.g>
        <path
          d="M790.324 241.001h8.024c30.577 0 55.365 23.749 55.365 53.044 0 29.295-24.788 53.044-55.365 53.044h-8.024c-30.578 0-55.366-23.749-55.366-53.044 0-29.295 24.788-53.044 55.366-53.044zM738.498 200.948h.314c5.378 0 9.737 4.176 9.737 9.329 0 5.152-4.359 9.328-9.737 9.328h-.314c-5.378 0-9.737-4.176-9.737-9.328 0-5.153 4.359-9.329 9.737-9.329zM762.998 201.249h.314c5.378 0 9.737 4.176 9.737 9.329 0 5.152-4.359 9.328-9.737 9.328h-.314c-5.378 0-9.737-4.176-9.737-9.328 0-5.153 4.359-9.329 9.737-9.329z"
          fill="#fff"
        />
      </g>
      <g>
        <path
          d="M531.326 137.712h125.059c5.737 0 10.388 6.738 10.388 15.05v198.645c0 8.312-4.651 15.05-10.388 15.05H531.326c-5.737 0-10.387-6.738-10.387-15.05V152.762c0-8.312 4.65-15.05 10.387-15.05z"
          fill="#333"
        />
        <motion.g
          animate={{
            scaleY: [1, 0, 1],
          }}
          transition={{ duration: 0.2, loop: Infinity, repeatDelay: 3 }}
        >
          <path
            d="M552.774 158.326h81.844c5.611 0 10.16 4.574 10.16 10.217v40.194c0 5.642-4.549 10.217-10.16 10.217h-81.844c-5.611 0-10.16-4.575-10.16-10.217v-40.194c0-5.643 4.549-10.217 10.16-10.217z"
            fill="url(#EmbeddedElectronics_svg__Z)"
          />
        </motion.g>
        <path
          d="M551.12 246.969h65.648c1.635 0 2.961 1.27 2.961 2.838 0 1.567-1.326 2.837-2.961 2.837H551.12c-1.635 0-2.961-1.27-2.961-2.837 0-1.568 1.326-2.838 2.961-2.838zM550.937 255.568h65.648c1.635 0 2.961 1.27 2.961 2.837 0 1.567-1.326 2.838-2.961 2.838h-65.648c-1.635 0-2.961-1.271-2.961-2.838 0-1.567 1.326-2.837 2.961-2.837zM550.937 263.816h65.648c1.635 0 2.961 1.27 2.961 2.837 0 1.567-1.326 2.838-2.961 2.838h-65.648c-1.635 0-2.961-1.271-2.961-2.838 0-1.567 1.326-2.837 2.961-2.837zM550.754 272.941h65.648c1.635 0 2.961 1.271 2.961 2.838 0 1.567-1.326 2.837-2.961 2.837h-65.648c-1.636 0-2.962-1.27-2.962-2.837 0-1.567 1.326-2.838 2.962-2.838zM550.754 281.54h65.648c1.635 0 2.961 1.271 2.961 2.838 0 1.567-1.326 2.837-2.961 2.837h-65.648c-1.636 0-2.962-1.27-2.962-2.837 0-1.567 1.326-2.838 2.962-2.838zM550.754 290.315h65.648c1.635 0 2.961 1.27 2.961 2.837 0 1.567-1.326 2.837-2.961 2.837h-65.648c-1.636 0-2.962-1.27-2.962-2.837 0-1.567 1.326-2.837 2.962-2.837zM550.388 299.265h65.647c1.636 0 2.962 1.27 2.962 2.837 0 1.567-1.326 2.837-2.962 2.837h-65.647c-1.636 0-2.962-1.27-2.962-2.837 0-1.567 1.326-2.837 2.962-2.837z"
          fill="#fff"
        />
      </g>
      <g>
        <path
          d="M321.741 188.734h146.943c6.74 0 12.205 5.236 12.205 11.694v154.336c0 6.458-5.465 11.693-12.205 11.693H321.741c-6.74 0-12.205-5.235-12.205-11.693V200.428c0-6.458 5.465-11.694 12.205-11.694z"
          fill="#333"
        />
        <path
          d="M445.468 214.579h.314c5.378 0 9.737 4.177 9.737 9.329 0 5.152-4.359 9.329-9.737 9.329h-.314c-5.378 0-9.737-4.177-9.737-9.329 0-5.152 4.359-9.329 9.737-9.329zM346.845 215.391h.314c5.378 0 9.737 4.177 9.737 9.329 0 5.152-4.359 9.329-9.737 9.329h-.314c-5.378 0-9.737-4.177-9.737-9.329 0-5.152 4.359-9.329 9.737-9.329zM345.68 252.917h102.739c4.422 0 8.008 3.049 8.008 6.81v80.551c0 3.762-3.586 6.811-8.008 6.811H345.68c-4.423 0-8.008-3.049-8.008-6.811v-80.551c0-3.761 3.585-6.81 8.008-6.81z"
          fill="#fff"
        />
        <motion.g
          animate={{
            scaleY: [1, 0, 1],
          }}
          transition={{ duration: 0.2, loop: Infinity, repeatDelay: 5 }}
        >
          <path
            d="M372.1 212.084h47.613c3.61 0 6.536 2.803 6.536 6.261v12.175c0 3.458-2.926 6.261-6.536 6.261H372.1c-3.609 0-6.535-2.803-6.535-6.261v-12.175c0-3.458 2.926-6.261 6.535-6.261z"
            fill="url(#EmbeddedElectronics_svg__aa)"
          />
        </motion.g>
        <path
          d="M348.557 285.773h96.035c2.145 0 3.884 1.014 3.884 2.266 0 1.251-1.739 2.266-3.884 2.266h-96.035c-2.145 0-3.884-1.015-3.884-2.266 0-1.252 1.739-2.266 3.884-2.266zM348.001 319.039h96.376c1.504 0 2.722 1.168 2.722 2.609 0 1.44-1.218 2.608-2.722 2.608h-96.376c-1.504 0-2.723-1.168-2.723-2.608 0-1.441 1.219-2.609 2.723-2.609z"
          fill="#333"
        />
        <path
          stroke="#333"
          strokeWidth={5.79}
          d="M337.565 252.431h121.549c2.251 0 4.075 1.748 4.075 3.904v4.965c0 2.156-1.824 3.904-4.075 3.904H337.565c-2.25 0-4.074-1.748-4.074-3.904v-4.965c0-2.156 1.824-3.904 4.074-3.904z"
          fill="#fff"
          strokeLinecap="butt"
          strokeLinejoin="miter"
        />
      </g>
      <g>
        <path
          d="M134.04 137.712h125.059c5.737 0 10.387 6.738 10.387 15.05v198.645c0 8.312-4.65 15.05-10.387 15.05H134.04c-5.737 0-10.388-6.738-10.388-15.05V152.762c0-8.312 4.651-15.05 10.388-15.05z"
          fill="#333"
        />
        <path d="M124.4 222.276l.28-.268" fill="none" />
        <motion.g
          animate={{
            scaleY: [1, 0, 1],
          }}
          transition={{ duration: 0.2, loop: Infinity, repeatDelay: 4 }}
        >
          <path
            d="M174.279 147.833h47.613c3.609 0 6.535 2.803 6.535 6.261v12.176c0 3.458-2.926 6.261-6.535 6.261h-47.613c-3.609 0-6.535-2.803-6.535-6.261v-12.176c0-3.458 2.926-6.261 6.535-6.261z"
            fill="url(#EmbeddedElectronics_svg__ab)"
          />
        </motion.g>
        <path
          d="M144.123 182.009h105.505c2.329 0 4.216 1.846 4.216 4.123v148.724c0 2.277-1.887 4.123-4.216 4.123H144.123c-2.328 0-4.216-1.846-4.216-4.123V186.132c0-2.277 1.888-4.123 4.216-4.123z"
          fill="#fff"
        />
        <path
          d="M138.565 202.215h119.753c.688 0 1.247.415 1.247.927s-.559.928-1.247.928H138.565c-.689 0-1.247-.416-1.247-.928s.558-.927 1.247-.927zM136.607 229.103h121.371c.698 0 1.264.415 1.264.927 0 .513-.566.928-1.264.928H136.607c-.698 0-1.264-.415-1.264-.928 0-.512.566-.927 1.264-.927zM131.856 255.229h129.158c.743 0 1.345.416 1.345.928s-.602.927-1.345.927H131.856c-.743 0-1.345-.415-1.345-.927s.602-.928 1.345-.928zM133.625 281.9h122.634c.705 0 1.277.415 1.277.927 0 .513-.572.928-1.277.928H133.625c-.706 0-1.278-.415-1.278-.928 0-.512.572-.927 1.278-.927zM135.875 310.748h122.976c.707 0 1.281.415 1.281.927 0 .513-.574.928-1.281.928H135.875c-.708 0-1.281-.415-1.281-.928 0-.512.573-.927 1.281-.927z"
          fill="#333"
        />
      </g>
    </svg>
  )
}

export default SvgEmbeddedElectronics
