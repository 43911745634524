import React from 'react'
import Link from '../../utilities/link'
import Img from 'gatsby-image'
import SlideInTop from '../utility/slideInTopAnimation'
import SlideInBottom from '../utility/slideInBottomAnimation'

const CallToAction = props => (
  <section className="hero is-medium is-gradient">
    <div className="hero-body">
      <div className="container">
        <div className="columns is-vcentered">
          {props.fixed && (
            <div className="column">
              <Img
                fixed={props.fixed}
                style={{
                  width: '560px',
                }}
                imgStyle={{
                  opacity: '0.1',
                }}
                alt={props.alt}
              />
            </div>
          )}
          {props.animatedIllustration && (
            <div className="column is-white">{props.animatedIllustration}</div>
          )}
          <div className="column">
              <div className="content text-center">
                <SlideInTop>
                  <h3
                    className="title is-3 is-white"
                    style={{ lineHeight: '1.5' }}
                  >
                    {props.title}
                  </h3>
                </SlideInTop>
                {props.buttonText && (
                  <SlideInBottom>
                    <Link
                      className="button is-light is-outlined is-medium"
                      to={props.link}
                    >
                      {props.buttonText}
                    </Link>
                  </SlideInBottom>
                )}
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default CallToAction
